import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DarkModeToggle from './DarkModeToggle';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-white dark:bg-gray-800 shadow-md border-b border-gray-200 dark:border-gray-700 fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img 
              src="/logo white bg small.png" 
              alt="VertiGo Systems Logo" 
              className="max-h-1"
            />
          </Link>
          <div className="flex items-center space-x-4">
            <nav className="hidden lg:block">
              <ul className="flex space-x-6 mr-10">
                <li><Link to="/" className="text-lg tracking-wider font-bold text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Home</Link></li>
                <li><Link to="/services" className="text-lg tracking-wider font-bold text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Services</Link></li>
                <li><Link to="/projects" className="text-lg tracking-wider font-bold text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Projects</Link></li>
                <li><Link to="/blog" className="text-lg tracking-wider font-bold text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Blog</Link></li>
                <li><Link to="/about" className="text-lg tracking-wider font-bold text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">About Us</Link></li>
                <li><Link to="/contact" className="text-lg tracking-wider font-bold text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Contact</Link></li>
              </ul>
            </nav>
            <DarkModeToggle />
            <button
              className="lg:hidden text-gray-600 dark:text-gray-300 focus:outline-none"
              onClick={toggleMenu}
              aria-label="Toggle menu"
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isMenuOpen ? (
                  <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                ) : (
                  <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                )}
              </svg>
            </button>
          </div>
        </div>
        <nav className={`${isMenuOpen ? 'block' : 'hidden'} lg:hidden mt-4`}>
          <ul className="space-y-4">
            <li><Link to="/" onClick={closeMenu} className="block text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Home</Link></li>
            <li><Link to="/about" onClick={closeMenu} className="block text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">About Us</Link></li>
            <li><Link to="/services" onClick={closeMenu} className="block text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Services</Link></li>
            <li><Link to="/projects" onClick={closeMenu} className="block text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Projects</Link></li>
            <li><Link to="/blog" onClick={closeMenu} className="block text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Blog</Link></li>
            <li><Link to="/contact" onClick={closeMenu} className="block text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">Contact</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;