import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

const Home = lazy(() => import('./pages/Home'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Construction = lazy(() => import('./pages/Construction'));
const Contact = lazy(() => import('./pages/Contact'));
const Services2 = lazy(() => import('./pages/Services'));
const Projects2 = lazy(() => import('./pages/Projects'));
const Blog2 = lazy(() => import('./pages/Blog'));

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Suspense fallback={<div className="text-center py-8">Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<Services2 />} />
              <Route path="/services2" element={<Services2 />} />
              <Route path="/projects" element={<Construction />} />
              <Route path="/projects2" element={<Projects2 />} />
              <Route path="/blog" element={<Blog2 />} />
              <Route path="/blog2" element={<Blog2 />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
        {/* Temporary change */}
        <div style={{display: 'none'}}>Refresh test</div>
      </div>
    </Router>
  );
}

export default App;