import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaFacebook, FaTwitter, FaYoutube } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Vertigo Systems NZ</h3>
            <p className="mb-4">Leading provider of innovative AV and security solutions in New Zealand.</p>
            <Link to="/about" className="text-blue-400 hover:text-blue-300">Learn More About Us</Link>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-gray-300">Home</Link></li>
              <li><Link to="/services" className="hover:text-gray-300">Our Services</Link></li>
              <li><Link to="/projects" className="hover:text-gray-300">Featured Projects</Link></li>
              <li><Link to="/blog" className="hover:text-gray-300">AV & Security Blog</Link></li>
              <li><Link to="/contact" className="hover:text-gray-300">Contact Vertigo Systems NZ</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Connect with Vertigo Systems NZ</h3>
            <div className="flex space-x-4">
              <a href="https://www.linkedin.com/company/vertigosystems" target="https://www.linkedin.com/company/vertigosystems" rel="noopener noreferrer" className="text-2xl hover:text-blue-400" aria-label="Vertigo Systems NZ LinkedIn">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/@Vertigo-Systems" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-blue-400" aria-label="Vertigo Systems NZ Facebook">
                <FaYoutube />
              </a>
              
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Stay Updated with Vertigo Systems NZ</h3>
            <form className="flex flex-col space-y-2">
              <input type="email" placeholder="Your email" className="px-4 py-2 text-gray-800" aria-label="Email for newsletter" />
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600 transition duration-300">Subscribe to Our Newsletter</button>
            </form>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p>&copy; {new Date().getFullYear()} Vertigo Systems NZ. All rights reserved.</p>
          <div className="mt-4 space-x-4">
            <Link to="/terms" className="hover:text-gray-300">Terms of Service</Link>
            <Link to="/privacy" className="hover:text-gray-300">Privacy Policy</Link>
            <Link to="/cookies" className="hover:text-gray-300">Cookie Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;